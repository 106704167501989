import { MainButtonProps } from './MainButton.interface';
import WebApp from '@twa-dev/sdk';

import { Button, FixedLayout, FormItem } from '@vkontakte/vkui';
import { FC, useEffect } from 'react';

const getButtonColorByStyle = (style): string => {
  switch (style) {
    case 'confirm':
      return '#45E688';
    case 'destructive':
      return '#FF4070';
    default:
      return WebApp.themeParams.button_color;
  }
};

const mainButton = WebApp.MainButton;
const { button_color, button_text_color } = WebApp.themeParams;

export const MainButton: FC<MainButtonProps> = ({
  disabled,
  style,
  text,
  onClick,
  progress,
}) => {
  const canMainButton = location.href.indexOf('localhost') === -1 && !window.isVK;

  useEffect(() => {
    return () => {
      mainButton.hide();
      mainButton.enable();
      mainButton.hideProgress();
      mainButton.setParams({
        color: button_color,
        text_color: button_text_color,
      });
    };
  }, []);

  useEffect(() => {
    if (typeof progress === 'boolean') {
      if (progress) {
        mainButton.showProgress();
        mainButton.disable();
      } else {
        mainButton.hideProgress();
      }
    }
    if (typeof disabled === 'boolean') {
      disabled || progress ? mainButton.disable() : mainButton.enable();
    }
  }, [disabled, progress]);

  useEffect(() => {
    if (style) {
      mainButton.setParams({ color: getButtonColorByStyle(style) });
    }
  }, [style]);

  useEffect(() => {
    if (text) {
      mainButton.setText(text);
      !mainButton.isVisible && mainButton.show();
    } else if (mainButton.isVisible) {
      mainButton.hide();
    }
  }, [text]);

  useEffect(() => {
    if (onClick) {
      WebApp.MainButton.onClick(onClick);
      return () => {
        WebApp.MainButton.offClick(onClick);
      };
    }
  }, [onClick]);

  if (canMainButton) {
    return null;
  }

  return (
    <>
      <div style={{ height: 68, flex: '0 0 auto', width: '100%' }} />
      <FixedLayout vertical="bottom" filled>
        <FormItem>
          <Button size="l" stretched onClick={onClick}>
            {text}
          </Button>
        </FormItem>
      </FixedLayout>
    </>
  );
};

// export const MainButton = memo(MainButtonComponent, (prev, next) => {
//   const canMainButton = location.href.indexOf('localhost') === -1;
//
//   if (WebApp.initData && canMainButton) {
//     if (prev.progress !== next.progress) {
//       WebApp.MainButton[next.progress ? 'showProgress' : 'hideProgress']();
//     }
//
//     if (prev.style !== next.style) {
//       WebApp.MainButton.setParams({
//         color: getButtonColorByStyle(next.style),
//         text_color: WebApp.themeParams.button_text_color,
//       });
//     }
//
//     if (prev.onClick !== next.onClick) {
//       buttonOnClicks.forEach((cb) => WebApp.MainButton.offClick(cb));
//       buttonOnClicks = [];
//
//       buttonOnClicks.push(next.onClick);
//       WebApp.MainButton.onClick(next.onClick);
//     }
//
//     if (next.text && prev.text !== next.text) {
//       WebApp.MainButton.setText(next.text);
//     }
//     return true;
//   }
//   return false;
// });
