export const VERSION = 5;

const DEV = false;
export const STAGING = !!process.env.REACT_APP_PUBLIC_URL?.includes('staging.');
export const PRODUCTION = ['tpc.fan', 'ton.place'].includes(window.location.host);

export const DEVELOPMENT_ENDPOINT = '127.0.0.1:8082';
export const PRODUCTION_ENDPOINT = process?.env.REACT_APP_ENDPOINT || 'preprod.ton.place';

export const ENDPOINT =
  location.host === 'staging.tonplace.host'
    ? 'api.tonplace.host'
    : DEV
    ? DEVELOPMENT_ENDPOINT
    : PRODUCTION_ENDPOINT;

export const API_PROTO = DEV ? 'http' : 'https';
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || 'https://ton.place';

export const API_URL_PRODUCTION = `${API_PROTO}://${PRODUCTION_ENDPOINT}`;
export const API_URL_STAGING = `${API_PROTO}://${DEVELOPMENT_ENDPOINT}`;

export const API_URL = `${API_PROTO}://${ENDPOINT}`;

export const TELEGRAM_BOT_ID = process?.env.REACT_APP_TELEGRAM_BOT_ID || 2141264283;

export const CONTEST_CUP = 500;

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAFEGyZXe4lELSgrV5lhxH9kQp46KeUKm4',
  authDomain: 'tonplace-f69ec.firebaseapp.com',
  projectId: 'tonplace-f69ec',
  storageBucket: 'tonplace-f69ec.appspot.com',
  messagingSenderId: '465231271647',
  appId: '1:465231271647:web:16297769cf21d2d7f47b6a',
  measurementId: 'G-3P1EDPGWV6',
};

export const VAPID_KEY =
  'BBajoWxT1aQc8dubYyIVFnOg42hlZx3c7e2voeL3mjrtE3si-4fpmXzEgDN-zLZtcvccpktoir_CY4nnI4oHcx4';
